import React from "react"
import Band from "../../components/band"

import Layout from "../../components/layout"

const MonitoringMI = () => (
  <Layout isMi={true}>
    <div className="promotion">
      <div className="cf-container">
        <div className="row monitoring-promo">
          <div className="col-md-6">
            <h3>Adults</h3>
            <p>
              Free two-year membership in IdentityWorks from Experian<sup>&reg;</sup>.
            </p>
            <p>
              You must have received a letter with an activation code and be 18
              years or older to enroll. The enrollment process requires personal
              information and each affected individual receiving a letter should
              complete their own enrollment. If you have children under 18 who were
              affected by this event, a separate letter will be sent to the parent
              or guardian with information on how to enroll in Experian’s<sup>&reg;</sup> IdentityWorks&#8480;.
            </p>
            <a className="btn btn-outline-light" rel="noreferrer" href="https://www.experianidworks.com/3bcredit" target="_blank">
              Enroll
            </a>
          </div>
          <div className="col-md-6">
            <h3>Services for Minor Children</h3>
            <p>
              Free two-year membership in Experian’s<sup>&reg;</sup> IdentityWorks&#8480;.
            </p>
            <p>
              Each child affected by this event will receive a letter in care of their
              parent or guardian allowing them to enroll in Experian’s<sup>&reg;</sup> IdentityWorks&#8480;, which provides
              monitoring for minors. These letters will include a distinct activation code.
              Only one adult in the household need enroll. You will be asked to provide
              information about yourself and affected children. For the highest level of
              coverage, adults who receive a letter should also enroll in IdentityWorks&#8480;.
            </p>
            <a className="btn btn-outline-light" rel="noreferrer" href="https://www.experianidworks.com/minorplus " target="_blank">
              Enroll
            </a>
          </div>
        </div>
      </div>
    </div>
    <Band>
      <h1 class="h1-button">
        Benefits of Enrollment
        <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target="#helpModal">
          Having trouble enrolling?
        </button>
      </h1>

      <h4 className="font-weight-semi mt-md-7">
        Receive a free two-year membership in credit monitoring and identity theft protection services from Experian*
      </h4>
      <p>
        Individuals over 18 who receive a notification letter from CareFirst are eligible to enroll in IdentityWorks&#8480; coverage. Each individual receiving a letter must enroll themselves as the enrollment process requires personal information. If you have dependents under 18 who were affected by this event, you will receive a separate letter on their behalf with directions on how to enroll in additional coverage through the IdentityWorks&#8480; Minor Plus service to help protect their identities.
      </p>

      <h2 className="mt-md-7">IdentityWorks for adults</h2>

      <p>
        A credit card is <strong>not</strong> required for enrollment in Experian IdentityWorks.
      </p>

      <p>
        You can contact Experian <strong>immediately</strong> regarding any fraud issues, and have access to the following features once you enroll in Experian IdentityWorks:
      </p>

      <ul>
        <li>
          <strong>Experian credit report at signup:</strong> See what information is associated with your credit file. Daily credit reports are available for online members only.*
        </li>
        <li>
          <strong>Credit Monitoring:</strong> Actively monitors Experian, Equifax and Transunion files for indicators of fraud.
        </li>
        <li>
          <strong>Identity Restoration:</strong> Identity Restoration specialists are immediately available to help you address credit and non-credit related fraud.
        </li>
        <li>
          <strong>Experian IdentityWorks ExtendCARE&trade;:</strong> You receive the same high-level of Identity Restoration support even after your Experian IdentityWorks membership has expired.
        </li>
        <li>
          <strong>Up to $1 Million Identity Theft Insurance**:</strong> Provides coverage for certain costs and unauthorized electronic fund transfers.
        </li>
      </ul>

      <h2 className="mt-md-7">Experian’s<sup>&reg;</sup> IdentityWorks&#8480; for minor dependents</h2>

      <p>
        A credit card is <strong>not</strong> required for enrollment in Experian IdentityWorks.
      </p>

      <p>
        You can contact Experian <strong>immediately</strong> regarding any fraud issues, and have access to the following features once you enroll in Experian IdentityWorks for your minor:
      </p>

      <ul>
        <li>
          <strong>Social Security Number Trace:</strong> Monitoring to determine whether enrolled minors in your household have an Experian credit report.  Alerts of all names, aliases and addresses that become associated with your minor’s Social Security Number (SSN) on the Experian credit report.        </li>
        <li>
          <strong>Internet Surveillance:</strong> Technology searches the web, chat rooms &amp; bulletin boards 24/7 to identify trading or selling of your personal information on the Dark Web.        </li>
        <li>
          <strong>Identity Restoration:</strong> Identity Restoration specialists are immediately available to help you address credit and non-credit related fraud.        </li>
        <li>
          <strong>Experian IdentityWorks ExtendCARE&trade;:</strong> You receive the same high-level of Identity Restoration support even after your Experian IdentityWorks membership has expired.
        </li>
        <li>
          <strong>Up to $1 Million Identity Theft Insurance**:</strong> Provides coverage for certain costs and unauthorized electronic fund transfers.
        </li>
      </ul>

      <hr className="mt-md-7"></hr>

      <h2 className="mt-md-7"> Additional Steps You Can Take To Protect Your Identity </h2>

      <strong>Obtain a copy of your credit report</strong>

      <p>
        You can obtain a copy of your credit report, free of charge, directly from each of the three nationwide credit reporting agencies. To order your credit report, free of charge once every 12 months, please visit www.annualcreditreport.com or call toll free at 1-877-322-8228. Contact information for the three nationwide credit reporting agencies is as follows:
      </p>

      <div className="row mb-5">
        <div className="col-md-3 offset-md-2">
          <strong>Equifax</strong> <br></br>
          PO Box 740256 <br></br>
          Atlanta, GA 30374 <br></br>
          www.equifax.com <br></br>
          1-800-525-6285 <br></br>
        </div>
        <div className="col-md-3">
          <strong>Experian</strong><br></br>
          PO Box 9554<br></br>
          Allen, TX 75013<br></br>
          www.experian.com<br></br>
          1-888-397-3742<br></br>
        </div>
        <div className="col-md-3">
          <strong>TransUnion</strong><br></br>
          PO Box 2000<br></br>
          Chester, PA 19022<br></br>
          www.transunion.com<br></br>
          1-800-680-7289<br></br>
        </div>
      </div>

      <p>
        Notify your financial institution immediately of any unauthorized transactions made or new accounts opened in your name.
      </p>

      <p>
        If you believe you are the victim of identity theft or have reason to believe your personal information has been misused, you should immediately contact the Federal Trade Commission and/or the Office of the Attorney General in your home state. Contact information for the Federal Trade Commission is as follows:
      </p>

      <div className="mb-5">
        Federal Trade Commission<br></br>
        600 Pennsylvania Avenue,<br></br>
        NW Washington,DC 20580<br></br>
        http://www.ftc.gov/idtheft<br></br>
        1-877-438-4338
      </div>

      <p>
        You can obtain information from these sources about steps an individual can take to avoid identity theft as well as information about fraud alerts and security freezes. You should also contact your local law enforcement authorities and file a police report. Obtain a copy of the police report in case you are asked to provide copies to creditors to correct your records.
      </p>
    </Band>



    <div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="helpModalLabel">Having trouble enrolling?</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&#10005;</span>
            </button>
          </div>
          <div class="modal-body p-small m-0">
            <p>
              Update your browser. The enrollment process works best when using Internet Explorer 11 or later, Firefox or Google Chrome.
          </p>
            <p>
              Call Experian directly on 888-451-6562 (outside the U.S., 479-573-6562). When enrolling over the phone, you will be asked for the engagement and personalized codes you received in your letter from CareFirst. If you already have an active Experian account you may need to use an alternate email address to enroll in a new account online or call Experian using the number above for help managing multiple accounts.
          </p>
          </div>
          <div class="modal-footer form-buttons">
            <button type="button" class="btn btn-secondary col-12 col-md-4 col-lg-3" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default MonitoringMI
